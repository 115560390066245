import React from "react";

function Footer() {
  return (
    <div className="bgFish">
      <div className="container">
        <div className="row justify-content-center text-center py-5">
          <div className="col-12 col-md-5 d-flex justify-content-center">
            <div>
              <a
                className="text-decoration-none"
                href="https://atlantisadventures.com"
              >
                <h3 className="text-light">HAWAII</h3>
              </a>
              <p>
                <a
                  className="text-light text-decoration-none"
                  href="https://atlantisadventures.com/waikiki"
                >
                  WAIKIKI
                </a>
              </p>
              <p>
                <a
                  className="text-light text-decoration-none"
                  href="https://atlantisadventures.com/maui"
                >
                  MAUI
                </a>
              </p>
              <p>
                <a
                  className="text-light text-decoration-none"
                  href="https://atlantisadventures.com/kona"
                >
                  KONA
                </a>
              </p>
            </div>
          </div>
          <div className="col-12 col-md-5 d-flex justify-content-center">
            <div>
              <h3 className="text-light">CARIBBEAN</h3>
              <p>
                <a
                  className="text-light text-decoration-none"
                  href="https://depalm.com/submarine-tours/atlantis-submarine-expedition/"
                >
                  ARUBA
                </a>
              </p>
              <p>
                <a
                  className="text-light text-decoration-none"
                  href="https://barbados.atlantissubmarines.com/"
                >
                  BARBADOS
                </a>
              </p>
              <p>
                <a
                  className="text-light text-decoration-none"
                  href="http://atlantissubmarines.travel/"
                >
                  COZUMEL
                </a>
              </p>
              <p className="mb-0">
                <a
                  className="text-light text-decoration-none"
                  href="https://www.caymanislandssubmarines.com/"
                >
                  GRAND CAYMAN
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-12 col-lg-7">
            <h3 className="text-light">ABOUT ATLANTIS SUBMARINES</h3>
            <p className="text-light">
              Atlantis Submarines currently has ten submarines and operates
              undersea tours in Aruba, Barbados, Cozumel, Guam, and in Hawaii at
              Waikiki, Maui, and Kona.
            </p>
          </div>
        </div>
        <div className="row text-center text-light">
          <p>© Atlantis Submarines | All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
