import React from "react";
import main from "../images/AtlantisKona.jpg";
import logo from "../images/atlantisSubmarines.png";

function Home() {
  return (
    <>
      <img
        src={main}
        alt="main"
        className="w-100 vh-100 object-fit-cover position-relative"
      />
      <div
        className="position-absolute start-50 translate-middle-x text-light text-center"
        style={{ top: "148px" }}
      >
        <img src={logo} alt="logo" style={{ height: "10rem" }} />
        <h1 className="textShadow text-center pt-4 mb-0">
          EXPORE OUR UNDERWATER WORLD
        </h1>
      </div>
    </>
  );
}

export default Home;
