import React from "react";
import logo from "../images/atlantisSubmarines.png";

function Header() {
  return (
    <>
      <nav
        className="navbar fixed-top navbar-expand-lg py-0"
        data-bs-theme="dark"
        style={{ backgroundColor: "rgba(1,1,1,.6)" }}
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo" style={{ height: "4rem" }} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse pe-0 pe-lg-5 text-center"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-light"
                  href="#0"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  HAWAII
                </a>
                <ul className="dropdown-menu text-center text-lg-start">
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://atlantisadventures.com/kona"
                    >
                      KONA
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://atlantisadventures.com/maui"
                    >
                      MAUI
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://atlantisadventures.com/waikiki"
                    >
                      WAIKIKI
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-light"
                  href="#0"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  CARIBBEAN
                </a>
                <ul className="dropdown-menu text-center text-lg-start">
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://depalm.com/submarine-tours/atlantis-submarine-expedition/"
                    >
                      ARUBA
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://barbados.atlantissubmarines.com/"
                    >
                      BARBADOS
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="http://atlantissubmarines.travel/"
                    >
                      COZUMEL
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.caymanislandssubmarines.com/"
                    >
                      GRAND CAYMAN
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
